angular.module('llax')
.controller('EditorHistoryController',
    function($scope, $rootScope, $translate, $filter, $location, $timeout, ItemHistoryResource, ItemEventHistoryResource,
    uiGridConstants, gridUtil, TabContainer, ContactsResource, CommunicationChannelResource, OrganizationService) {

            var EDITOR_HISTORY_GRID_STATES_KEY = "editor-history-grid-states-";
            var DEFAULT_GRID_STATE_KEY = "default-state";

            $scope.EVENT_ACTOR_TYPES = {
                USER: 'USER',
                ORGANIZATION: 'ORGANIZATION',
                COMM_CHANNEL: 'COMM_CHANNEL',
                OTHER: 'OTHER'
            };

            $scope.gridOptions = {};
            $scope.columnDefs = {};
            $scope.historyData = [];
            $scope.enableWrapping = {};

            gridUtil.on.mousewheel = function() {};

            $scope.createCallout = createCallout;
            $scope.encodeAssetLink = encodeAssetLink;

            var channels = [];

            var userTemplate =
                '<div class="ui-grid-cell-contents ui-grid-cell-pointer">\n' +
                '    <span data-ng-if="row.entity.actorType === grid.appScope.EVENT_ACTOR_TYPES.USER" data-ng-click="grid.appScope.createCallout(\'history-user-image\', row.entity)">\n' +
                '        <img data-ng-if="row.entity.userDetails.imageUrl" data-ng-src="{{row.entity.userDetails.imageUrl}}" class="history-user-image">\n' +
                '        <i data-ng-if="!row.entity.userDetails.imageUrl" class="history-user-image syncons syncons-user"></i>\n' +
                '        <span>{{row.entity.userDetails.displayName}}</span>\n' +
                '    </span>\n' +
                '    <span data-ng-if="row.entity.actorType === grid.appScope.EVENT_ACTOR_TYPES.COMM_CHANNEL" data-ng-click="grid.appScope.createCallout(\'history-comm-channel\', row.entity)">\n' +
                '       <i class="history-comm-channel syncons syncons-channels"></i>\n' +
                '       {{row.entity.commChannel.name}}\n' +
                '    </span>\n' +
                '    <span data-ng-if="row.entity.actorType === grid.appScope.EVENT_ACTOR_TYPES.ORGANIZATION" data-ng-click="grid.appScope.createCallout(\'history-user-image\', row.entity)">\n' +
                '       <img data-ng-if="row.entity.organization.imageUrl" data-ng-src="{{row.entity.organization.imageUrl}}" class="history-user-image">\n' +
                '       <i data-ng-if="!row.entity.organization.imageUrl" class="history-user-image syncons syncons-contacts"></i>\n' +
                '       <span>{{row.entity.organization.name}}</span>\n' +
                '    </span>\n' +
                '    <span data-ng-if="row.entity.actorType === grid.appScope.EVENT_ACTOR_TYPES.OTHER">\n' +
                '       <span>{{row.entity.senderName}}</span>\n' +
                '    </span>\n' +
                '</div>';

            function onTabActivate(tab) {
                $scope.currentSubUrl = tab.suburl;
                switch (tab.suburl) {
                    case 'timeline':
                        createTableDefinition('timeline');
                        break;
                    case 'attributes':
                        createTableDefinition('attributes');
                        break;
                    case 'events':
                        createTableDefinition('events');
                        break;
                    default:
                        break;
                }
            }

            function initTabs() {
                var location = {
                    hash: function(url) {
                        return url;
                    }
                };
                var tabsConfig = [{
                        title: $translate.instant('HISTORY.TAB_TITLE.TIMELINE'),
                        suburl: 'timeline',
                        template: 'tpl/item-history-timeline.tpl.html'
                    }, {
                        title: $translate.instant('HISTORY.TAB_TITLE.ATTRIBUTES'),
                        suburl: 'attributes',
                        template: 'tpl/item-history-attributes.tpl.html'
                    }, {
                        title: $translate.instant('HISTORY.TAB_TITLE.EVENTS'),
                        suburl: 'events',
                        template: 'tpl/item-history-events.tpl.html'
                    }];

                var tabContainer = new TabContainer($scope, location, tabsConfig, 'timeline', onTabActivate);
                tabContainer.openSubview(location.hash());
            }

            function getItemHistory(primaryKey) {

                ItemHistoryResource.get({
                    primaryKey: primaryKey
                },
                function(itemHistory) {

                    var attributeHistory = [];

                    _.forEach(itemHistory.history, function(history) {

                        var updatedAt = history.updatedAt__;
                        var updatedBy = history.updatedBy__;

                        _.forEach(history, function(value, key) {

                            if (key === "checksum__" || key === "updatedAt__" || key === "updatedBy__") {
                                return;
                            }

                            var pos, attribute;

                            pos = key.indexOf('[');
                            if (pos > 0) {
                                attribute = $rootScope.dataModel.attribute(key.substring(0, pos));
                            } else {
                                attribute = $rootScope.dataModel.attribute(key);
                            }

                            var label = $rootScope.translateAttribute(attribute);

                            var oldValue = value.oldValue__;
                            var oldValueClass = value.oldValueClass__;
                            oldValue = formatValue(oldValue, oldValueClass);
                            var currentValue = value.currentValue__;
                            var currentValueClass = value.currentValueClass__;
                            currentValue = formatValue(currentValue, currentValueClass);

                            var entry = {
                                user: updatedBy,
                                date: updatedAt,
                                attributeElement: key,
                                attributeLabel: label,
                                previousValue: oldValue,
                                currentValue: currentValue
                            };

                            if (entry.user) {
                                entry.actorType = $scope.EVENT_ACTOR_TYPES.USER;

                                var user = $rootScope.getUser(entry.user);

                                // Sometimes, we might have a user but it's no longer defined in the system
                                // For example: in a backup restore situation.
                                if (!_.isNil(user)) {
                                    entry.userDetails = {
                                        userName: user.userId,
                                        fullName: user.fullName,
                                        displayName: user.displayName,
                                        email: user.email,
                                        lastLogin: $rootScope.getFilter('date')(new Date(user.lastLogin), 'medium'),
                                        imageUrl: user.imageUrl
                                    };
                                } else {
                                    entry.actorType = $scope.EVENT_ACTOR_TYPES.OTHER;
                                    entry.senderName = entry.user;
                                }
                            } else {
                                entry.actorType = $scope.EVENT_ACTOR_TYPES.OTHER;
                            }

                            entry.additionalText = "Attribute '" + label + "' was changed to '" + currentValue + "'";
                            entry.additionalText = $rootScope.translate('HISTORY.ATTRIBUTE_CHANGED_TEXT', entry.additionalText, {
                                attribute: label,
                                value: currentValue
                            });

                            attributeHistory.push(entry);

                        });

                    });

                    function formatValue(value, valueClass) {
                        if (valueClass === 'Date' || (_.isNil(valueClass) && _.isInteger(value))) {
                            value = $filter('date')(value, 'medium');
                        } else if (valueClass === 'Number') {
                            value = $filter('formatFloatValue')(value);
                        }
                        return value;
                    }

                    $scope.historyData.attributes = attributeHistory;

                    $scope.historyData.timeline = _.map(attributeHistory, function(entry) {
                        var additionalText = "Attribute '" + entry.attributeLabel + "' was changed to '" + entry.currentValue + "'";
                        additionalText = $rootScope.translate('HISTORY.ATTRIBUTE_CHANGED_TEXT', additionalText, {
                            attribute: entry.attributeLabel,
                            value: entry.currentValue
                        });
                        return {
                            date: entry.date,
                            user: entry.user,
                            userName: entry.userName,
                            userImageUrl: entry.userImageUrl,
                            event: 'ATTRIBUTE_CHANGE',
                            status: 'SUCCESS',
                            additionalText: additionalText,
                            userDetails: entry.userDetails,
                            actorType: entry.actorType,
                            senderName: entry.senderName
                        };
                    });

                    ItemEventHistoryResource.query({
                        primaryKey: primaryKey
                    }, function(itemEventHistory) {

                        $scope.historyData.events = _.map(itemEventHistory, function(entry) {

                            entry.assetLink = createAssetLink(entry);

                            entry.actorType = getActorType(entry);

                            if(entry.actorType === $scope.EVENT_ACTOR_TYPES.USER) {
                                var user = $rootScope.getUser(entry.user);

                                // Sometimes, we might have a user but it's no longer defined in the system
                                // For example: in a backup restore situation.
                                if (!_.isNil(user)) {
                                    entry.userDetails = {
                                        userName: user.userId,
                                        fullName: user.fullName,
                                        displayName: user.displayName,
                                        email: user.email,
                                        lastLogin: $rootScope.getFilter('date')(new Date(user.lastLogin), 'medium'),
                                        imageUrl: user.imageUrl
                                    };
                                } else {
                                    entry.actorType = $scope.EVENT_ACTOR_TYPES.OTHER;
                                    entry.senderName = entry.user;
                                }
                            } else if (entry.actorType === $scope.EVENT_ACTOR_TYPES.ORGANIZATION) {
                                entry.organization = getOrganization(entry.sender);
                            } else if (entry.actorType === $scope.EVENT_ACTOR_TYPES.COMM_CHANNEL) {
                                var commChannel = getCommChannel(entry.channelId);

                                // Sometimes a communication channel is no longer defined in the system
                                if (!_.isNil(commChannel)) {
                                    entry.commChannel = commChannel;
                                } else {
                                    entry.actorType = $scope.EVENT_ACTOR_TYPES.OTHER;
                                    entry.senderName = entry.channelId;
                                }
                            }

                            if (entry.recipient) {
                                entry.recipientName = getEventPartnerName(entry.transferType,
                                                                          entry.recipient,
                                                                          entry.channelId,
                                                                          entry.subDestination);
                            }

                            return entry;
                        });

                        $scope.historyData.timeline = $scope.historyData.timeline.concat(itemEventHistory);

                    });

                });

            }

            function createAssetLink(event) {
                if (event.assetPath && event.assetType == 'IMPORT') {
                    return lax_rest_url("gatherings/") + event.assetId + event.assetPath + "?download=true";
                } else if (event.assetPath && event.assetType == 'MESSAGING') {
                    return lax_rest_url("messaging/messagingassets/") + event.assetId + event.assetPath + "?download=true";
                }
            }

            function loadColumnDefinitions() {
                $scope.columnDefs = {
                    'timeline': [{
                        field: 'date',
                        displayName: $translate.instant('HISTORY.MODIFIED_WHEN'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellFilter: "date:'medium'",
                        width: 150,
                        sort: {
                            direction: uiGridConstants.DESC
                        }
                    }, {
                        field: 'user',
                        displayName: $translate.instant('HISTORY.MODIFIED_BY'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellTemplate: userTemplate,
                        width: 160
                    }, {
                        field: 'event',
                        displayName: $translate.instant('HISTORY.EVENT'),
                        headerTooltip: true,
                        cellFilter: 'translate',
                        cellTooltip: true,
                        width: 160
                    }, {
                        field: 'additionalText',
                        displayName: $translate.instant('HISTORY.ADDITIONAL_TEXT'),
                        headerTooltip: true,
                        cellTooltip: true
                    },{
                        field: 'assetLink',
                        displayName: $translate.instant('HISTORY.LINK'),
                        headerTooltip: true,
                        width: 140,
                        cellTemplate:
                            '<div class="ui-grid-cell-contents text-center" data-ng-show="row.entity.assetLink">\n' +
                            '    <a data-ng-href="{{grid.appScope.encodeAssetLink(row.entity.assetLink)}}" target="_self">\n' +
                            '        <i class="syncons syncons-download"></i>\n' +
                            '    </a>\n' +
                            '</div>'
                    }],
                    'attributes': [{
                        field: 'date',
                        displayName: $translate.instant('HISTORY.MODIFIED_WHEN'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellFilter: "date:'medium'",
                        width: 150,
                        sort: {
                            direction: uiGridConstants.DESC
                        }
                    }, {
                        field: 'user',
                        displayName: $translate.instant('HISTORY.MODIFIED_BY'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellTemplate: userTemplate,
                        width: 160
                    }, {
                        field: 'attributeLabel',
                        displayName: $translate.instant('HISTORY.ATTRIBUTE_LABEL'),
                        headerTooltip: true,
                        cellTooltip: true,
                        width: 160,
                        cellTemplate:
                            '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-atrribute-label" class="ui-grid-cell-contents">\n' +
                                '{{COL_FIELD}}\n' +
                            '</div>'
                    }, {
                        field: 'attributeElement',
                        displayName: $translate.instant('HISTORY.ATTRIBUTE_ELEMENT'),
                        headerTooltip: true,
                        cellTooltip: true,
                        width: 160,
                        cellTemplate:
                            '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-atrribute-element" class="ui-grid-cell-contents">\n' +
                                '{{COL_FIELD}}\n' +
                            '</div>'
                    }, {
                        field: 'previousValue',
                        displayName: $translate.instant('HISTORY.OLD_VALUE'),
                        headerTooltip: true,
                        cellTooltip: true,
                        type: 'string',
                        cellTemplate:
                            '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-old_value" title="{{COL_FIELD}}" class="ui-grid-cell-contents">\n' +
                                '{{COL_FIELD}}\n' +
                            '</div>'
                    }, {
                        field: 'currentValue',
                        displayName: $translate.instant('HISTORY.NEW_VALUE'),
                        headerTooltip: true,
                        cellTooltip: true,
                        type: 'string',
                        cellTemplate:
                            '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-new_value" title="{{COL_FIELD}}" class="ui-grid-cell-contents">\n' +
                                '{{COL_FIELD}}\n' +
                            '</div>'
                    }],
                    'events': [{
                        field: 'date',
                        displayName: $translate.instant('HISTORY.MODIFIED_WHEN'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellFilter: "date:'medium'",
                        width: 150,
                        sort: {
                            direction: uiGridConstants.DESC
                        }
                    }, {
                        field: 'user',
                        displayName: $translate.instant('HISTORY.MODIFIED_BY'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellTemplate: userTemplate,
                        width: 160
                    }, {
                        field: 'recipient',
                        displayName: $translate.instant('MESSAGING.RECIPIENT'),
                        headerTooltip: true,
                        cellTooltip: true,
                        cellTemplate:
                            '<div class="ui-grid-cell-contents" title="{{row.entity.recipientName}}">\n' +
                                '<span data-ng-if="row.entity.recipientName">\n' +
                                    '{{row.entity.recipientName}}\n' +
                                '</span>' +
                            '</div>',
                        width: 140,
                    }, {
                        field: 'event',
                        displayName: $translate.instant('HISTORY.EVENT'),
                        headerTooltip: true,
                        cellFilter: 'translate',
                        cellTooltip: true,
                        width: 140
                    }, {
                        field: 'additionalText',
                        displayName: $translate.instant('HISTORY.ADDITIONAL_TEXT'),
                        headerTooltip: true,
                        cellTooltip: true
                    }, {
                        field: 'text',
                        displayName: $translate.instant('HISTORY.TEXT'),
                        headerTooltip: true,
                        cellTooltip: true
                    },{
                        field: 'assetLink',
                        displayName: $translate.instant('HISTORY.LINK'),
                        headerTooltip: true,
                        width: 140,
                        cellTemplate:
                            '<div class="ui-grid-cell-contents text-center" data-ng-show="row.entity.assetLink">\n' +
                            '    <a data-ng-href="{{grid.appScope.encodeAssetLink(row.entity.assetLink)}}" target="_self">\n' +
                            '        <i class="syncons syncons-download"></i>\n' +
                            '    </a>\n' +
                            '</div>'
                    }, {
                        field: 'status',
                        displayName: $translate.instant('STATUS'),
                        headerTooltip: true,
                        width: 90,
                        headerCellClass: 'text-center',
                        cellClass: 'text-center',
                        cellTemplate:
                            '<div class="ui-grid-cell-contents">\n' +
                            '    <span class="label" data-ng-class="grid.appScope.getStatusLabel(COL_FIELD)">\n' +
                            '        {{COL_FIELD | translate}}\n' +
                            '    </span>\n' +
                            '</div>'
                    }]
                };
            }

            function createTableDefinition(optionId) {
                var gridStatesKey = EDITOR_HISTORY_GRID_STATES_KEY + optionId;
                $scope.gridOptions[optionId] = {
                    data: 'historyData.' + optionId,
                    columnDefs: $scope.columnDefs[optionId],
                    enableColumnMenus: false,
                    enableGridMenu: true,
                    gridMenuCustomItems : [
                        {
                            title: $translate.instant('WRAP_CONTENT'),
                            active: function() {
                                return $scope.enableWrapping[gridStatesKey];
                            },
                            action: function ($event) {
                                toggleWrapping(gridStatesKey);
                            },
                            order: 210
                        }
                    ],
                    onRegisterApi: function(gridApi) {
                        $scope.gridApi = gridApi;
                        $rootScope.initGridState($scope, $scope.gridApi, gridStatesKey, DEFAULT_GRID_STATE_KEY, $scope.columnDefs[optionId]);
                    }
                };
            }

            function toggleWrapping(gridStatesKey) {
                $scope.enableWrapping[gridStatesKey] = !$scope.enableWrapping[gridStatesKey];
                $scope.$broadcast(gridStatesKey);
            }

            function getEventPartnerName(transferType, partner, channelId, subDestination) {
                var partnerName;
                if (transferType === 'IN_PLATFORM') {
                    var organization = _.find($scope.organizations, function(entry) {
                        // In-platform publications can only exist for organiztation contacts,
                        // thus we only need to compare the (optional) property 'organizationId'!
                        return entry.organizationId == partner;
                    });
                    if (organization) {
                        partnerName = organization.name;
                    }
                } else if (transferType === 'COMMUNICATION_CHANNEL') {
                    var channel = _.find(channels, {id: channelId});
                    if (subDestination) {
                        if (channel && channel.subDestinations && channel.subDestinations[subDestination]) {
                            partnerName = channel.subDestinations[subDestination] + ' (' + subDestination + ')';
                        }
                        partnerName = partnerName || subDestination;
                    } else if (channel) {
                        partnerName = channel.name;
                    }
                } else {
                    partnerName = $rootScope.getUserName(partner);
                }
                return partnerName ? partnerName : partner;
            }

            function loadAllVisibleOrganizations() {
                ContactsResource.query({},
                    function(response) {
                        $scope.organizations = response;
                    }
                );
            }

            function loadCommunicationChannels() {
                CommunicationChannelResource.query({}, function(response) {
                    channels = response;
                });
            }

            function createCallout(target, entity) {
                var customRendererFn;
                var imageElement;

                if (entity.actorType === $scope.EVENT_ACTOR_TYPES.USER) {
                    imageElement = '<div class="icon-container"><span class="syncons syncons-user"></span></div>';
                    if (entity.userDetails.imageUrl) {
                        imageElement = '<div class="photo"><img src="' + entity.userDetails.imageUrl + '" alt="user photo" /></div>';
                    }

                    customRendererFn = function(){
                        return '<div class="userinfo">' +
                            '   <div class="userinfo-row">' +
                            '       <div class="hero-background"></div>' +
                            '       <div class="name-section">' +
                            '           <div class="small-4 columns">' +
                                            imageElement +
                            '           </div>' +
                            '           <div class="small-8 columns info">' +
                            '               <h4 class="name">' + entity.userDetails.fullName + '</h4>' +
                            '               <h4 class="type">' + entity.userDetails.userName + '</h4>' +
                            '           </div>' +
                            '       </div>' +
                            '       <div class="small-12 columns about">' +
                            '           <p><i class="syncons syncons-email"></i>' + entity.userDetails.email + '</p>' +
                            '           <p><i class="syncons syncons-history"></i>' + $translate.instant('USER_MANAGEMENT.LAST_LOGGED_IN') + ' ' + entity.userDetails.lastLogin + '</p>' +
                            '       </div>' +
                            '   </div>' +
                            '</div>' +
                            '<button class="hopscotch-bubble-close hopscotch-close">Close</button>' +
                            '<span class="hopscotch-arrow"></span>';
                    };
                } else if (entity.actorType === $scope.EVENT_ACTOR_TYPES.COMM_CHANNEL) {
                    customRendererFn = function() {
                        return '<div class="userinfo">' +
                            '   <div class="userinfo-row">' +
                            '       <div class="hero-background"></div>' +
                            '       <div class="name-section">' +
                            '           <div class="small-4 columns">' +
                            '               <div class="icon-container"><i class="syncons syncons-channels"></i></div>' +
                            '           </div>' +
                            '           <div class="small-8 columns info">' +
                            '               <h4 class="name">' + entity.commChannel.name + '</h4>' +
                            '               <h4 class="type">' + $translate.instant('COMMUNICATION_CHANNELS') + '</h4>' +
                            '           </div>' +
                            '       </div>' +
                            '       <div class="small-12 columns about">' +
                            '           <p><i class="syncons syncons-home"></i>' + entity.commChannel.partnerAddress + '</p>' +
                            '           <p><i class="syncons syncons-documentation"></i>' + entity.commChannel.planLabel + '</p>' +
                            '           <p><i class="syncons syncons-options"></i>' + entity.commChannel.typeLabel + '</p>' +
                            '       </div>' +
                            '   </div>' +
                            '</div>' +
                            '<button class="hopscotch-bubble-close hopscotch-close">Close</button>' +
                            '<span class="hopscotch-arrow"></span>';
                    };
                } else if (entity.actorType === $scope.EVENT_ACTOR_TYPES.ORGANIZATION) {
                    imageElement = '<div class="icon-container"><i class="syncons syncons-contacts"></i></div>';
                    if (entity.organization.imageUrl) {
                        imageElement = '<div class="photo"><img src="' + entity.organization.imageUrl + '" alt="user photo" /></div>';
                    }

                    customRendererFn = function () {
                        return '<div class="userinfo">' +
                            '   <div class="userinfo-row">' +
                            '       <div class="hero-background"></div>' +
                            '       <div class="name-section">' +
                            '           <div class="small-4 columns">' +
                                            imageElement +
                            '           </div>' +
                            '           <div class="small-8 columns info">' +
                            '               <h4 class="name">' + entity.organization.name + '</h4>' +
                            '               <h4 class="type">' + $translate.instant('CONTACTS') + '</h4>' +
                            '           </div>' +
                            '       </div>' +
                            '       <div class="small-12 columns about">' +
                            '           <p data-ng-if="entity.organization.email"><i class="syncons syncons-email"></i>' + entity.organization.email + '</p>' +
                            '           <p data-ng-if="entity.organization.address"><i class="syncons syncons-home"></i>' + entity.organization.address + '</p>' +
                            '       </div>' +
                            '   </div>' +
                            '</div>' +
                            '<button class="hopscotch-bubble-close hopscotch-close">Close</button>' +
                            '<span class="hopscotch-arrow"></span>';
                    };
                }

                var calloutConfig = {
                    id: 'callout-' + target,
                    target: '.' + target,
                    placement: 'bottom',
                    content: '',
                    customRenderer: customRendererFn
                };

                var calloutMgr = hopscotch.getCalloutManager();
                calloutMgr.removeAllCallouts();

                var calloutEle = calloutMgr.createCallout(calloutConfig);
                // Hack: override !important css rules, with inline precedence
                calloutEle.element.style.cssText += 'border: none !important;';
            }

            function getActorType(entry) {
                if (entry.user) {
                    return $scope.EVENT_ACTOR_TYPES.USER;
                } else if (entry.transferType === 'IN_PLATFORM') {
                    return $scope.EVENT_ACTOR_TYPES.ORGANIZATION;
                } else if (entry.transferType === 'COMMUNICATION_CHANNEL') {
                    return $scope.EVENT_ACTOR_TYPES.COMM_CHANNEL;
                } else {
                    return $scope.EVENT_ACTOR_TYPES.OTHER;
                }
            }

            function getOrganization(organizationId) {
                return _.find($scope.organizations, function (entry) {
                    // In-platform publications can only exist for organiztation contacts,
                    // thus we only need to compare the (optional) property 'organizationId'!
                    return entry.organizationId == organizationId;
                });
            }

            function getCommChannel(channelId) {
                var channel = _.find(channels, { id: channelId });
                if (!channel) {
                    return null;
                }
                channel.planLabel = getChannelPlanLabel(channel.plan);
                channel.typeLabel = getChannelTypeLabel(channel.type);
                return channel;
            }

            function getChannelPlanLabel(plan) {
                if (!plan) {
                    return "";
                }

                var planDetails = $rootScope.communicationPlans[plan];
                if (!planDetails) {
                    return "";
                }

                return planDetails.label;
            }

            function getChannelTypeLabel(type) {
                if (!type) {
                    return "";
                }

                return $rootScope.translateValue('COMMUNICATION_CHANNELS.TYPES.', type);
            }

            function encodeAssetLink(assetLink) {
                return encodeURI(assetLink);
            }

            (function init() {
                loadAllVisibleOrganizations();
                loadCommunicationChannels();
                loadColumnDefinitions();
                getItemHistory($scope.item.primaryKey__);
                initTabs();
            })();
        }
    );

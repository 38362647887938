angular.module('llax')
    .controller('ReportsController',
        function($scope, $translate, $modal, $q, uiGridConstants, ExportSummaryResource, JobResource, FeatureToggleService, growl) {

            var jobColumnDefs = [{
                field: 'schedule.startDateTime',
                displayName: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.startDateTime'),
                headerTooltip: true,
                cellFilter: "date:'medium'",
                cellClass: 'text-center',
                width: 140,
                sort: {
                    direction: uiGridConstants.DESC,
                    priority: 0
                }
            }, {
                field: 'name',
                displayName: $translate.instant('NAME'),
                headerTooltip: true,
                cellTooltip: true,
                minWidth: 120
            }, {
                field: 'schedule.nextRunDateTime',
                displayName: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.nextRunDateTime'),
                headerTooltip: true,
                cellFilter: "date:'medium'",
                cellClass: 'text-center',
                width: 140,
                sort: {
                    direction: uiGridConstants.ASC,
                    priority: 1
                }
            }, {
                field: 'format',
                displayName: $translate.instant('EXPORT.MAPPING_PROFILES.LABEL'),
                headerTooltip: true,
                cellTooltip: true,
                cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                    '<span>{{COL_FIELD.split("__mappingname__=")[1]}}</span>\n' +
                    '</div>',
                width: 140
            }, {
                field: 'emailRecipients',
                displayName: $translate.instant('MESSAGING.RECIPIENT'),
                headerTooltip: true,
                cellTooltip: true,
                cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                    '<span>{{COL_FIELD.join(\', \')}}</span>\n' +
                    '</div>',
                width: 180
            }, {
                field: 'schedule.active',
                displayName: $translate.instant('ACTIVE'),
                headerTooltip: true,
                headerCellClass: 'text-center',
                cellClass: 'text-center',
                cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                    '<i class="syncons" data-ng-dblclick="grid.appScope.toggleActive(row.entity)" data-ng-class="{\'syncons-checkmark\': row.entity.schedule.active, \'syncons-minus\': !row.entity.schedule.active}"></i>\n' +
                    '</div>',
                width: 60
            }, {
                field: 'schedule.status',
                displayName: $translate.instant('STATUS'),
                headerTooltip: true,
                headerCellClass: 'text-center',
                cellClass: 'text-center',
                cellTemplate: '<div class="ui-grid-cell-contents text-center">\n' +
                    '<span class="label" data-ng-class="grid.appScope.getStatusLabel(row.entity.schedule.status)">\n' +
                    '{{row.entity.schedule.status | translate}}\n' +
                    '</span>\n' +
                    '</div>',
                width: 100
            }, {
                displayName: $translate.instant('ACTIONS'),
                headerTooltip: true,
                name: 'actions',
                headerCellClass: 'text-right',
                cellClass: 'text-right',
                enableColumnResizing: false,
                enableSorting: false,
                pinnedRight: true,
                width: 140,
                cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                    '<div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions">\n' +
                        '<button type="button" class="btn btn-cell action-run action-btn-slot" title="{{\'RUN_JOB\' | translate}}" data-ng-click="grid.appScope.runJob(row.entity)">\n' +
                            '<i class="glyphicon glyphicon-play"></i>\n' +
                        '</button>\n' +
                        '<button type="button" class="btn btn-cell action-edit action-btn-slot" title="{{\'EDIT_JOB\' | translate}}" data-ng-click="grid.appScope.editJob(row.entity)">\n' +
                            '<i class="syncons syncons-edit"></i>\n' +
                        '</button>\n' +
                        '<button type="button" class="btn btn-cell action-delete action-btn-slot" title="{{\'DELETE_JOB\' | translate}}" data-ng-click="grid.appScope.deleteJob(row.entity)">\n' +
                            '<i class="syncons syncons-delete"></i>\n' +
                        '</button>\n' +
                    '</div>\n' +
                    '</div>'
            }];

            var reportColumnDefs = [{
                field: 'creationDate',
                displayName: $translate.instant('CREATED_AT'),
                headerTooltip: true,
                width: 140,
                cellFilter: "date:'medium'"
            }, {
                field: 'creationUser',
                displayName: $translate.instant('USER'),
                headerTooltip: true,
                cellClass: 'text-center',
                width: 120,
                cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                    '  <span>{{::grid.appScope.getUserName(COL_FIELD)}}</span>\n' +
                    '</div>'
            }, {
                field: 'name',
                displayName: $translate.instant('NAME'),
                headerTooltip: true,
                minWidth: 140
            }, {
                field: 'format',
                displayName: $translate.instant('FORMAT'),
                headerTooltip: true,
                minWidth: 160
            }, {
                field: 'status',
                displayName: $translate.instant('STATUS'),
                headerTooltip: true,
                width: 100,
                cellClass: 'text-center',
                cellTemplate: '<div class="ui-grid-cell-contents text-center">\n' +
                    '<span class="label" data-ng-class="grid.appScope.getStatusLabel(row.entity.status)">\n' +
                    '{{row.entity.status | translate}}\n' +
                    '</span>\n' +
                    '</div>'
            }, {
                field: 'actions',
                displayName: $translate.instant('ACTIONS'),
                headerTooltip: true,
                width: 100,
                headerCellClass: 'text-right',
                cellClass: 'text-right',
                pinnedRight: true,
                cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                    '  <div id="row{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" class="actions">\n' +
                    '    <a class="btn btn-cell" data-ng-href="/api/v1/gatherings/{{row.entity.id}}{{row.entity.digitalAssetPath}}?download=true" target="_self">\n' +
                    '      <i class="syncons syncons-download"></i>\n' +
                    '    </a>\n' +
                    '  </div>\n' +
                    '</div>'
            }];

            function loadReports() {
                return ExportSummaryResource.query({
                        type: 'EXPORT',
                        status: 'FINISHED,ERROR'
                    },
                    function(response) {
                        $scope.reports = response;
                        _.forEach($scope.reports, function(entry) {
                            var job = _.find($scope.jobs, {
                                id: entry.jobId
                            });
                            entry.creationUser = '';
                            entry.name = job ? job.name : '';
                        });
                    },
                    function(response) {
                        $scope.reports = [];
                    }
                );
            }

            function loadExportJobs() {
                return JobResource.query({
                    type: 'EXPORT'
                }, function(response) {
                    $scope.jobs = response;
                }, function(response) {
                    $scope.jobs = [];
                });
            }

            $scope.jobGridOptions = {
                data: 'jobs',
                columnDefs: jobColumnDefs,
                enableColumnMenus: false
            };

            $scope.gridOptions = {
                data: 'reports',
                columnDefs: reportColumnDefs,
                enableColumnMenus: false
            };

            $scope.createReport = function() {
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/create-report-modal.tpl.html',
                    controller: 'EditReportController',
                    backdrop: true,
                    windowClass: 'publish-modal',
                    resolve: {
                        data: {}
                    }
                });
                modalInstance.result.then(function(foo) {
                    console.log('Saved');
                }, function() {
                    console.log('Modal dismissed..');
                });
            };

            $scope.toggleActive = function(job) {
                var newJob = angular.copy(job);
                newJob.type = 'EXPORT';
                newJob.schedule.active = !newJob.schedule.active;
                JobResource.save(newJob, function(response) {
                    angular.extend(job, response);
                });
            };

            $scope.runJob = function(entity) {
                JobResource.run({
                    jobId: entity.id
                }, function(response) {
                    growl.success("Report started successfully!");
                }, function(errorResponse) {
                    growl.success("Report could not be run!");
                });
            };

            $scope.deleteJob = function(entity) {
                JobResource.delete({
                    jobId: entity.id
                }, function(response) {
                    growl.success("Report deleted successfully!");
                    _.remove($scope.jobs, {
                        id: entity.id
                    });
                }, function(errorResponse) {
                    growl.error("Report could not be deleted!");
                });
            };

            $scope.editJob = function(entity) {
                var modalInstance = $modal.open({
                    templateUrl: 'tpl/edit-report-modal.tpl.html',
                    controller: 'EditReportController',
                    backdrop: true,
                    resolve: {
                        data: function() {
                            return entity;
                        }
                    }
                });
                modalInstance.result.then(function(foo) {
                    console.log('Saved');
                }, function() {
                    console.log('Modal dismissed..');
                });
            };

            (function init() {
                if (FeatureToggleService.checkFeatureEnabled('reports')) {
                    $q.all([
                        loadExportJobs().$promise,
                        loadReports().$promise
                    ]).then(function(data) {
                        $scope.isLoading = false;
                    });
                }
            })();
        }
    );

angular.module('llax')
    .controller('UploadsController', function($scope, $rootScope, $translate, $modal, $timeout, $filter, $dialogs, ImportResource, QueryImportResource,
        UploadMappingResource, RunMappingResource, growl, ChannelService, UsageLimitsResource, uiGridConstants, HttpHeader) {

        var IMPORT_GRID_STATES_KEY = "import-grid-states";
        var DEFAULT_GRID_STATE_KEY = "default-state";

        $rootScope.loadDataModel();
        $scope.entries = [];

        $scope.error = {
            key: "attribute_required",
            attribute: "Category"
        };

        $scope.uploadFile = function() {
            return {
                url: lax_rest_url('gatherings'),
                filters: ['noImageFilter'],
                uploadSuccess: function(response) {
                    growl.success("UPLOAD_SUCCESS_MESSAGE");
                },
                uploadCancelled: function(response) {
                    growl.error("UPLOAD_CANCELLED_MESSAGE");
                }
            };
        };

        UploadMappingResource.query({},
            function(response) {
                $scope.mapping = response;
            },
            function(response) {
                $scope.mapping = null;
            }
        );

        function getGatheringsTemplate(field, key) {
            return '<div class="ui-grid-cell-contents text-right">' +
            '  <span data-ng-if="row.entity.' + field +' == 0">\n' +
            '    {{row.entity.' +field+ '}}\n' +
            '  </span>  ' +
            '  <a data-ng-if="row.entity.'+ field +' > 0" data-download data-ng-href="/browse?tags=upload:{{row.entity.gatheringKey}}:' + key + '" target="_self">\n' +
            '    {{row.entity.'+ field +'}}\n' +
            '  </a>' +
            '</div>';
        }

        $scope.columnDefs = [
        {
            field: 'totalImported',
            displayName: $translate.instant('UPLOAD.ITEMS'),
            headerTooltip: true,
            cellTemplate: '<div class="ui-grid-cell-contents text-center" ng-class="{\'cell-status-running\':row.entity.status == \'RUNNING\'}">\n' +
                '  <span>{{row.entity.totalImported}}/{{row.entity.numberOfItemsToImport}}</span>\n' +
                '  <a ng-if="row.entity.status == \'RUNNING\'" class="progress-bar-container" href="#"\n' +
                '      tooltip-append-to-body="true" data-tooltip="{{row.entity.totalImported}} of {{row.entity.numberOfItemsToImport}} completed.">\n' +
                '    <progressbar-custom type="dynamic" value="row.entity.totalImported" max="row.entity.numberOfItemsToImport"></progressbar-custom>\n' +
                '    </a>\n' +
                '</div>',
            width: 120
        },
        {
            field: 'status',
            displayName: $translate.instant('STATUS'),
            headerTooltip: true,
            width: 90,
            cellTemplate: '<div class="ui-grid-cell-contents text-center">' +
                '  <span data-ng-if="row.entity.status != \'ERROR\'" class="label" data-ng-class="grid.appScope.getStatusLabel(row.entity.status)"\n' +
                '       tooltip-placement="top" tooltip-append-to-body="true" tooltip-html-unsafe="{{row.entity.message}}" tooltip-trigger="mouseenter">\n' +
                '    {{ row.entity.status | translate}}\n' +
                '  </span>' +
                '  <span data-ng-if="row.entity.status == \'ERROR\'" class="label" data-ng-class="grid.appScope.getStatusLabel(row.entity.status)"\n' +
                '       data-ng-click="grid.appScope.showError(row.entity)" style="cursor:pointer;">\n' +
                '    {{ row.entity.status | translate}}\n' +
                '  </span>' +
                '</div>'
        },
        {
            field: 'path',
            headerTooltip: true,
            cellTooltip: true,
            displayName: $translate.instant('FILE'),
            minWidth: 160
        },
        {
            field: 'newItems',
            displayName: $translate.instant('NEW'),
            headerTooltip: true,
            width: 60,
            cellClass: 'text-right',
            cellTemplate: getGatheringsTemplate('newItems','new')
        },
        {
            field: 'failedItems',
            displayName: $translate.instant('FAILED'),
            headerTooltip: true,
            width: 60,
            cellTemplate: '<div class="ui-grid-cell-contents text-right" >' +
            '  <span class="text-right">\n' +
            '    {{row.entity.failedItems}}\n' +
            '  </span>  ' +
            ' <a style="padding:0 6px" data-ng-if="row.entity.reportPath && row.entity.failedItems > 0" class="btn btn-cell action-btn-slot uploaded-file-download-btn" ' +
            '      data-download ' +
            '      data-ng-href="/api/v1/gatherings/{{row.entity.gatheringKey}}{{grid.appScope.getPath(row.entity.reportPath)}}?download=true" target="_self">\n' +
            '      <i class="glyphicon glyphicon-download-alt"></i>\n' +
            '    </a>' +
            '</div>'
        },
        {
            field: 'changedItems',
            displayName: $translate.instant('CHANGED'),
            headerTooltip: true,
            width: 60,
            cellClass: 'text-right',
            cellTemplate: getGatheringsTemplate('changedItems','changed')
        }, {
            field: 'unchangedItems',
            displayName: $translate.instant('UNCHANGED'),
            headerTooltip: true,
            width: 60,
            cellClass: 'text-right',
            cellTemplate: getGatheringsTemplate('unchangedItems','unchanged')
        },
        {
            field: 'creationDate',
            displayName: $translate.instant('PUBLICATIONS.START'),
            cellFilter: "date:'medium'",
            headerTooltip: true,
            width: 150
        },
        {
            field: 'finishedDate',
            displayName: $translate.instant('PUBLICATIONS.END'),
            cellFilter: "date:'medium'",
            headerTooltip: true,
            width: 150
        }, {
            field: 'creationUser',
            displayName: $translate.instant('USER'),
            headerTooltip: true,
            cellTooltip: true,
            width: '*',
            minWidth: 120,
            cellTemplate: '<div class="ui-grid-cell-contents text-left">\n' +
                '  <span>{{grid.appScope.getUserName(COL_FIELD)}}</span>\n' +
                '</div>'
        }];

        $scope.columnDefs.push({
            displayName: $translate.instant('ACTIONS'),
            headerTooltip: true,
            name: 'actions',
            headerCellClass: 'text-center',
            cellClass: 'text-right',
            enableCellEdit: false,
            enableCellSelection: false,
            allowCellFocus: false,
            pinnedRight: true,
            enableColumnResizing: false,
            enableSorting: false,
            enableHiding: false,
            resizable: false,
            width: 140,
            cellTemplate: '<div class="ui-grid-cell-contents">' +
                '  <div id="row-{{rowRenderIndex}}-actions" class="actions">\n' +
                '    <button data-ng-if="grid.appScope.canStartMappingEditor(row.entity)"' +
                '      data-ng-click="grid.appScope.startMappingEditor(row.entity)"' +
                '      type="button" value="startMappingEditor" class="btn btn-cell action-btn-slot uploaded-file-mapping-btn">\n' +
                '      <i class="syncons syncons-edit"></i>\n' +
                '    </button>\n' +
                '    <button data-ng-if="grid.appScope.canRunMapping(row.entity) && grid.appScope.limitsOK(row.entity.numberOfItemsToImport)" ' +
                '      data-ng-click="grid.appScope.runMapping(row.entity)" ' +
                '      type="button" value="runMapping" class="btn btn-cell action-btn-slot uploaded-file-run-btn">\n' +
                '      <i class="glyphicon glyphicon-play"></i>\n' +
                '    </button>\n' +
                '    <span data-ng-if="grid.appScope.canRunMapping(row.entity) && !grid.appScope.limitsOK(row.entity.numberOfItemsToImport)" ' +
                '      class="icon validation-icon" title="Import not possible" ' +
                '      tooltip-append-to-body="true" tooltip-placement="left" ' +
                '      tooltip-html-unsafe="{{ grid.appScope.getTooManyItemsMessage(row) }}" tooltip-trigger="mouseenter">\n' +
                '      <i class="syncons syncons-error"></i>\n' +
                '    </span>\n' +
                '    <a class="btn btn-cell action-btn-slot uploaded-file-items-btn" ' +
                '      data-ng-show="row.entity.changedItems > 0 || row.entity.newItems > 0 || row.entity.unchangedItems > 0" ' +
                '      data-ng-href="/browse?tags=upload:{{row.entity.gatheringKey}}:all" target="_self" title="{{\'IMPORTS.INCLUDED\' | translate}}">\n' +
                '      <i class="syncons syncons-item-list"></i>\n' +
                '    </a>\n' +
                '    <a class="btn btn-cell action-btn-slot uploaded-file-items-btn" ' +
                '      data-ng-show="row.entity.changedItems > 0 || row.entity.newItems > 0 || row.entity.unchangedItems > 0" ' +
                '      data-ng-href="/browse?tags=^upload:{{row.entity.gatheringKey}}:all" target="_self" title="{{\'IMPORTS.NOT_INCLUDED\' | translate}}">\n' +
                '      <i class="syncons syncons-items-not-included"></i>\n' +
                '    </a>\n' +
                '    <a class="btn btn-cell action-btn-slot uploaded-file-download-btn" ' +
                '      data-download ' +
                '      title="{{\'IMPORTS.DOWNLOAD_FILE\' | translate}}" ' +
                '      data-ng-href="/api/v1/gatherings/{{row.entity.gatheringKey}}{{grid.appScope.getPath(row.entity.path)}}?download=true" target="_self">\n' +
                '      <i class="glyphicon glyphicon-download-alt"></i>\n' +
                '    </a>\n' +
                '    <button data-ng-if="row.entity.status==\'UPLOADED\' || row.entity.status==\'READY\' || row.entity.status==\'IGNORED\'" data-ng-click="grid.appScope.deleteImport(row.entity)" ' +
                '      type="button" class="btn btn-cell action-btn-slot delete-file-btn">\n' +
                '      <i class="syncons syncons-delete"></i>\n' +
                '    </button>\n' +
                '  </div>\n' +
                '</div>'
        });

        _.forEach($scope.columnDefs, function(col) {
            col.cellClass = function(grid, row, col, rowRenderIndex, colRenderIndex) {
                return 'row' + rowRenderIndex + ' col' + colRenderIndex;
            };
        });

        $scope.gridOptions = {
            data: 'entries',
            columnDefs: $scope.columnDefs,
            enableColumnMenus: false,
            enableGridMenu: true,
            enableSorting: false,
            infiniteScrollDown: true,
            rowHeight: 34,
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                gridApi.infiniteScroll.on.needLoadMoreData($scope, $scope.loadMoreEntries);
                $rootScope.initGridState($scope, $scope.gridApi, IMPORT_GRID_STATES_KEY, DEFAULT_GRID_STATE_KEY, $scope.columnDefs);
            }
        };

        $scope.getPath = function(p) {
            // We should only encode (encodeURIComponent) the URL parameter value i.e. without the '/'
            if (p && p[0] === '/') {
                return '/' + encodeURIComponent(p.slice(1, p.length));
            } else {
                return encodeURIComponent(p);
            }
        };

        $scope.loadMoreEntries = function() {
            $scope.loadingItems = true;
            $scope.fetchEntries({append: true});
        };

        $scope.fetchEntries = function(config) {
            var queryParams = {};
            if (_.isUndefined(config)) {
                queryParams = {
                    limit: 50
                };
            } else if (config.append) {
                queryParams = {
                    limit: config.limit || 50
                };
            }

            QueryImportResource.createCursorAwareResource($scope.cursor).query(queryParams,
                function(response, headers) {
                    var cursor = headers(HttpHeader.MESSAGE_CURSOR);
                    if (cursor) {
                        $scope.cursor = cursor;
                    }

                    if (response.length > 0) {
                        $scope.entries = $scope.entries.concat(response);
                        if ($scope.gridApi) {
                            $timeout(function() {
                                $scope.gridApi.infiniteScroll.saveScrollPercentage();
                                $scope.gridApi.infiniteScroll.dataLoaded(false, true);
                            });
                        }
                    }
                });
        };

        $scope.$on('channelMessageReceived', function(event, eventData) {
            if (eventData.event === ChannelService.IMPORT_SUMMARY_CHANGED_EVENT) {
                var newEntry = eventData.data.entry;
                if (!_.includes(newEntry.tags, 'upload')) {
                    return;
                }
                $scope.entries = $scope.entries || [];
                var existingEntry = _.find($scope.entries, {id: newEntry.id});
                if (!_.isNil(existingEntry)) {
                    if (newEntry.updatedDate > existingEntry.updatedDate) {
                        angular.extend(existingEntry, newEntry);
                        $scope.gridApi.grid.notifyDataChange(uiGridConstants.dataChange.EDIT);
                    }
                } else {
                    $scope.entries.unshift(newEntry);
                }
            }
        });

        var StartMappingEditor = function($rootScope, $scope, $modalInstance, RunMappingResource, growl, data, $location, $anchorScroll) {

            var DEFAULT_TARGETS = {
                category: 'category__',
                primaryKey: 'primaryKey__'
            };

            $scope.dataModel = $rootScope.dataModel.filteredLayoutAttributes('import_mapping');

            var parentscope = data.parentscope;

            function getValue(key) {

                var target = null;

                if (!_.isEmpty(parentscope.mapping)) {

                    var foundEntry = _.find(parentscope.mapping, function(entry) {
                        if (entry.source == key) {
                            return true;
                        }
                    });
                    if (!_.isNil(foundEntry)) {
                        target = foundEntry.target;
                    }

                }

                if (_.isNil(target)) {
                    target = key.indexOf("[") !== -1 ? key.substring(0,key.indexOf("[")) : key;
                }

                // Only allow target if it is defined in the data model
                if (!$scope.isModelDefined(target) && !$scope.isModelDefined(DEFAULT_TARGETS[target])) {
                    target = null;
                }

                return target;
            }

            function isIgnored(key) {
                var ignored = false;
                if (parentscope.mapping && parentscope.mapping.length > 0) {
                    var i = parentscope.mapping.length;
                    while (i--) {
                        if (parentscope.mapping[i].source == key) {
                            ignored = parentscope.mapping[i].ignored;
                        }
                    }
                }
                return ignored;
            }

            function getOptions() {
                var values = $scope.dataModel.map(function(elem) {
                    return {
                        name: elem.name,
                        label: $rootScope.translateAttribute(elem)
                    };
                });
                return values;
            }

            $scope.isModelDefined = function(name) {
                return _.find($scope.dataModel, {
                    'name': name
                }, 'name') !== undefined;
            };

            $scope.potentialPrimaryKeyPart = function(name) {
                return $rootScope.dataModel.potentialPrimaryKeyPart(name);
            };

            function getNonMappedAttributes(entries) {
                return _.filter(entries, function(entry) {
                    return !$scope.isModelDefined(entry.source) && !entry.target;
                });
            }

            $scope.tagTransform = function(newTag) {
                var item = {
                    name: newTag,
                    label: newTag
                };
                return item;
            };

            $scope.onSelect = function(entry, $select) {
                if ($select.selected.name === "") {
                    delete $select.selected;
                    return false;
                }
                if (entry.oldTarget !== undefined) {
                    $scope.alreadyMapped[entry.oldTarget] = false;
                }
                if ($select.selected !== undefined && entry.target !== undefined && entry.target !== "") {
                    entry.ignored = false;
                    $scope.alreadyMapped[entry.target] = true;
                } else {
                    entry.ignored = true;
                }
                $scope.nonMappedAttributes = getNonMappedAttributes($scope.data);
            };

            $scope.diplaySelected = function(entry, attribute) {
                if (attribute !=null && (entry.source).indexOf("[") !== -1){
                  return ((entry.source).indexOf(attribute.name) > -1) ?  entry.source : attribute.name ;
                }
                return attribute!=null ? ( attribute.name != null ? attribute.name : attribute ) : attribute ;
            };

            $scope.alreadyMapped = {};
            $scope.setMapped = function(entry) {
                if (entry.target) {
                    $scope.alreadyMapped[entry.target] = true;
                }
            };

            $scope.notMappedAlready = function(option) {
                var attr = _.find($scope.dataModel , { name: option.name});
                if(attr.baseClass=="Hash" || attr.baseClass=="Array"){
                   return true;
                }
                return !$scope.alreadyMapped[option.name];
            };

            $scope.entity = data.entity;

            $scope.data = [];

            $scope.entity.columnNames = $scope.entity.columnNames.filter(function(e,i,s){
                return i == s.indexOf(e);
            });

            for (var i = 0; i < $scope.entity.columnNames.length; i++) {
                var name = $scope.entity.columnNames[i];
                var target = getValue(name);
                var ignored = isIgnored(name) && !$scope.potentialPrimaryKeyPart(target);
                var entry = {
                    'source': name,
                    'target': target,
                    'oldTarget': target,
                    'ignored': ignored
                };
                $scope.data.push(entry);
            }
            $scope.options = getOptions();
            $scope.nonMappedAttributes = getNonMappedAttributes($scope.data);

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            };

            $scope.run = function() {
                $scope.data = _.reject($scope.data, function(key, value) {
                    return key === '$$hashKey';
                });

                $scope.data = $scope.data.map(function(entry){
                    if ((entry.source).indexOf("[") !== -1 && (entry.source).indexOf(entry.target) !== -1){
                      entry.target = entry.source ;
                    }
                    return entry;
                });

                UploadMappingResource.save({},
                    $scope.data,
                    function(response) {
                        growl.success("UPLOADED_MAPPING_SUCCESS");
                        parentscope.mapping = $scope.data;
                        parentscope.runMapping($scope.entity);
                        $modalInstance.close();
                    },
                    function(response) {
                        growl.error("UPLOADED_MAPPING_FAILED");
                        $scope.mapping = null;
                        $modalInstance.close();
                    }
                );
            };
        };
        StartMappingEditor.$inject = ["$rootScope", "$scope", "$modalInstance", "RunMappingResource", "growl", "data", "$location"];
        $rootScope.usageLimits = UsageLimitsResource.get();

        $scope.limitsOK = function(number) {
            if ($rootScope.usageLimits.$resolved) {
                if ($rootScope.usageLimits.limits.ITEMS_PER_IMPORT_FILE > 0) {
                  return $rootScope.usageLimits.limits.ITEMS_PER_IMPORT_FILE > number;
                }
              return $rootScope.usageLimits.limits.ITEMS > number;
            }
            return false;
        };

        $scope.getTooManyItemsMessage = function(data) {
            return $translate.instant("USAGE_LIMIT.ITEMS_IMPORT_EXCEEDS");
        };

        $scope.canRunMapping = function(entity) {
            // Note: 'UPLOADED' is the old long deprecated status!
            return entity.status === 'READY' || entity.status === 'UPLOADED';
        };

        $scope.runMapping = function(entity) {
            RunMappingResource.save({}, entity,
                function(response) {
                    growl.success("RUN_MAPPING_SUCCESS");
                    entity.status = "RUNNING";
                },
                function(response) {
                    growl.success("RUN_MAPPING_FAILED");
                });
        };

        $scope.canStartMappingEditor = function(entity) {
            return $rootScope.systemSettings.IMPORT_MAPPING_ENABLED && $scope.canRunMapping(entity);
        };

        $scope.startMappingEditor = function(entity) {
            ImportResource.get({
                id: entity.id
            }, function(summary) {
                $modal.open({
                    templateUrl: 'tpl/import-mapping-editor.tpl.html',
                    controller: StartMappingEditor,
                    resolve: {
                        data: function() {
                            return {
                                entity: summary,
                                parentscope: $scope
                            };
                        }
                    },
                    backdrop: true,
                    windowClass: 'publish-modal'
                });
            });
        };

        $scope.deleteImport = function(entity) {
            var fileName = $filter('fileName')(entity.path) || '';
            var confirmDialog = $dialogs.confirm('CONFIRM_DELETE', 'IMPORTS.DELETE_CONFIRMATION_TEXT',{variables:{name:fileName}});
            confirmDialog.result.then(function() {
                ImportResource.delete({
                    id: entity.id
                }, function(data) {
                    $scope.cursor = null;
                    $scope.entries = [];
                    $scope.fetchEntries();
                }, function (error) { });
            });
        };

        $scope.showError = function (row) {
            console.log(row);
            var modalPromise = $modal.open({
                templateUrl: 'tpl/error-details.tpl.html',
                controller: 'errorDetailsController',
                backdrop: true,
                resolve: {
                    row: function() {
                        return row;
                    }
                }
            });
        };

        (function init() {
            $scope.fetchEntries();
        })();
    });

angular.module('llax')
    .controller('ItemDetailController',
        function($controller, $rootScope, $routeParams, ReactBridge, $scope, $location, $log, $filter, $q, $window,
            AdditionalCategoryAttributeService, Auth, growl, MEDIA_TYPE_ONLINE_CATALOG, $timeout, ExportMappingResource, $modal) {

            var CUSTOM_ITEM_REVIEW_SERVICE = "CustomItemReviewSettings";
            var LOCAL_STORAGE_SHOW_REVIEW_KEY = 'onlineCatalog.showReviewPanel';

            $scope.images = [];
            $scope.documents = [];
            $scope.media = [];
            $scope.firstSection = null;
            $scope.dynamicColumns = {};
            $scope.dynamicDesktopData = [];
            $scope.dynamicMobileData = [];
            $scope.collapse = [];
            $scope.itemReviewEnabled = false;
            $scope.showReviewPanel = false;
            $scope.loading = true;

            $scope.currentLayout = 'detail';
            $scope.itemTitle = null;
            $scope.currentItemIndex = null;
            $scope.isInEditorMode = false;
            $scope.primaryKey__ = $routeParams.primaryKey;
            // Tapping into online catalog state to enable a prev/next navigation
            $scope.onlineCatalogState = $rootScope.onlineCatalogState;

            $controller('FetchItemController', {
                $scope: $scope
            });

            // Dummy dependencies for SearchQueryController, which are used for the export functionality.
            $scope.query = {};
            $scope.allItemsSelected = false;
            $scope.itemsSelected = function() { return true; };
            $scope.getSelectedItems = function() {
                return [ $scope.primaryKey__ ];
            };

            $rootScope.loadDataModel();

            // ItemDetailController could be loaded from within the EditorController.
            // In that case $scope.item is already available.
            $scope.item = $scope.item || null;
            if (_.isNil($scope.item)) {
                if ($rootScope.isDataModelLoaded) {
                    init();
                } else {
                    $rootScope.$on('dataModelLoaded', function() {
                        init();
                    });
                }
            } else {
                $scope.isInEditorMode = true;
            }

            $scope.returnToCatalog = function() {
                $location.path('/catalog').search('checksum', null);
            };

            function loadSectionsAsync() {
                var deferred = $q.defer();
                if ($rootScope.dataModel.hasCategory($scope.item.category__) && $rootScope.dataModel.hasLayout($scope.currentLayout)) {
                    AdditionalCategoryAttributeService.loadAdditionalSectionAttributes($scope.currentLayout, $scope.item, function(additionalSectionAttributes) {
                        $scope.sections = $rootScope.dataModel.filteredSections($scope.currentLayout, $scope.item, additionalSectionAttributes);
                        reloadImages();
                        initPanels();
                        initItemTitle();
                        deferred.resolve();
                    });
                }

                return deferred.promise;
            }

            function reloadImages() {
                $scope.media = [];
                $scope.documents = [];
                $scope.images = [];
                for (var i = 0; i < $scope.sections.length; i++) {
                    var section = $scope.sections[i];
                    for (var j = 0; j < section.attributes.length; j++) {
                        var attribute = section.attributes[j];
                        if (attribute.typeName == 'Collection' || attribute.typeName == 'Group') {
                            addCollection($scope.item[attribute.name], attribute);
                        } else if (attribute.typeName == 'Image') {
                            addImage($scope.item[attribute.name], attribute);
                        } else if (attribute.typeName == 'Document') {
                            var file = $scope.item[attribute.name];
                            if (!_.isNil(file)) {
                                if ($filter('isFileType')(file, 'drawable')) {
                                    addImage(file, attribute);
                                } else {
                                    addDocument(file, attribute);
                                }
                            }
                        }
                    }
                }

                $scope.media = _.concat($scope.images, $scope.documents);
            }

            function addCollection(collection, attribute) {
                if (collection && collection.length > 0) {
                    angular.forEach(collection, function(row, groupRowIndex) {
                        var memberAttributes = $rootScope.dataModel.getMemberAttributes(attribute);
                        angular.forEach(memberAttributes, function(attr) {
                            if (attr.typeName == 'Image') {
                                addImage(row[attr.name], attr);
                            } else if (attr.typeName == 'Document' ) {
                                var file = row[attr.name];
                                if (!_.isNil(file)) {
                                    if ($filter('isFileType')(file, 'drawable')) {
                                        addImage(file, attr);
                                    } else {
                                        addDocument(row[attr.name], attr);
                                    }
                                }
                            } else if (attr.typeName == 'Collection') {
                                addCollection($scope.item[attribute.name][groupRowIndex][attr.name], attr);
                            }
                        });
                    });
                }
            }

            function addImage(file, attribute) {
                if (_.isNil(file)) {
                    return;
                }

                if (file && (file.startsWith("http"))) {
                    $scope.images.push({
                        url: file.replace("\\", ""),
                        name: attribute.label,
                        type: MEDIA_TYPE_ONLINE_CATALOG.IMAGE
                    });
                }
            }

            function addDocument(file, attribute) {
                if (_.isNil(file)) {
                    return;
                }

                if (file && (file.startsWith("http"))) {
                    $scope.documents.push({
                        url: file.replace("\\", ""),
                        name: attribute.label,
                        type: MEDIA_TYPE_ONLINE_CATALOG.DOCUMENT
                    });
                }

            }

            function initPanels() {
                // for catalog item detail - accordion
                if ($scope.sections) {
                    $scope.oneAtATime = false;
                    $scope.panelStatus = {
                        isOpen: new Array($scope.sections.length)
                    };
                    for (var i = 0; i < $scope.panelStatus.isOpen.length; i++) {
                        $scope.panelStatus.isOpen[i] = true;
                    }
                }
            }

            $scope.$on('$routeChangeStart', function($event, next, current) {
                // Delete any online catalog state if we are navigating outside
                // of online catalogs scope.
                if (!_.isNil(next) && !_.isNil(next.$$route) &&
                    next.$$route.controller != 'CatalogController' &&
                    next.$$route.controller != 'ItemDetailController') {
                    delete $rootScope.onlineCatalogState;
                }
            });

            $scope.getCurrentItemIndex = function() {
                if (!_.isNil($scope.onlineCatalogState) && !_.isEmpty($scope.onlineCatalogState.items)) {
                    return _.reduce($scope.onlineCatalogState.items, function(acc, currentValue, index) {
                        if (currentValue.primaryKey__ === $scope.item.primaryKey__ && acc === null) {
                            return index;
                        } else {
                            return acc;
                        }
                    }, null);
                } else {
                    return null;
                }
            };

            $scope.prepareItemReview = function() {
                var itemReviewCustomService =  $rootScope.getService(CUSTOM_ITEM_REVIEW_SERVICE);
                if (!_.isNil(itemReviewCustomService)) {
                    $scope.itemReviewEnabled = itemReviewCustomService.enableItemReview($scope.item, $rootScope.user, $rootScope.organization) || false;
                }
            };

            $scope.expandAll = function() {
                $scope.panelStatus.isOpen = _.map($scope.panelStatus.isOpen, function(accordionIndex) {
                    return true;
                });
            };

            $scope.collapseAll = function() {
                $scope.panelStatus.isOpen = _.map($scope.panelStatus.isOpen, function(accordionIndex) {
                    return false;
                });
            };

            $scope.openPreviousItem = function() {
                if ($scope.currentItemIndex > 0) {
                    var previousItemPrimaryKey = $scope.onlineCatalogState.items[$scope.currentItemIndex - 1].primaryKey__;
                    if ($location.search().withPublicationTaskChecksums) {
                        $location.path('/items/detail/' + previousItemPrimaryKey).search('checksum', item.checksum__);
                    } else {
                        $location.path('/items/detail/' + previousItemPrimaryKey);
                    }
                }
            };

            $scope.openNextItem = function() {
                if ($scope.currentItemIndex < $scope.onlineCatalogState.items.length - 1) {
                    var nextItemPrimaryKey = $scope.onlineCatalogState.items[$scope.currentItemIndex + 1].primaryKey__;
                    if ($location.search().withPublicationTaskChecksums) {
                        $location.path('/items/detail/' + nextItemPrimaryKey).search('checksum', item.checksum__);
                    } else {
                        $location.path('/items/detail/' + nextItemPrimaryKey);
                    }
                }
            };

            $scope.copyItemLink = function() {
                var url = $location.protocol() + '://' + $location.host();
                if ($location.port() && $location.port() != 443) {
                    url += ':' + $location.port();
                }
                url += '/items/detail/' + $routeParams.primaryKey;

                navigator.clipboard.writeText(url).then(function() {
                    growl.success('ONLINE_CATALOG.LINK_COPIED_TO_CLIPBOARD');
                });
            };

            // Called from within the editor layout to initialize the controller.
            $scope.initItemDetailsWithinEditor = function() {
                var urlPath = window.location.pathname;
                var pathSegments = urlPath.split('/');
                var primaryKey = pathSegments[2];
                $timeout(function () {
                    loadSectionsAsync().then(function() {
                            ReactBridge.mountComponent("ItemDetails", "#item-details-component", {
                            primaryKey: primaryKey,
                            showReviewPanel: $scope.showReviewPanel,
                            isInEditorMode: $scope.isInEditorMode
                        });
                    });
                }, 100);
            };

            $scope.showHideReviewPanel = function() {
                $scope.showReviewPanel = !$scope.showReviewPanel;
                $window.localStorage.setItem(LOCAL_STORAGE_SHOW_REVIEW_KEY, $scope.showReviewPanel);
                $rootScope.$broadcast('showReviewPanelChanged', $scope.showReviewPanel);
            };

            $scope.hasMedia = function() {
                return $scope.media && $scope.media.length > 0;
            };

            $scope.loadReviewPanelPreferences = function() {
                if (!_.isNil($window.localStorage.getItem(LOCAL_STORAGE_SHOW_REVIEW_KEY))) {
                    var shouldShowPanel = $window.localStorage.getItem(LOCAL_STORAGE_SHOW_REVIEW_KEY);
                    try {
                        $scope.showReviewPanel = JSON.parse(shouldShowPanel);
                    } catch(e) {
                        $log.error('OnlineCatalog: Failed to parse local storage key ' + LOCAL_STORAGE_SHOW_REVIEW_KEY);
                    }
                }
            };

            $scope.hasEditItemRights = function () {
                return Auth.hasRights('edit.items', 'create.items', 'copy.items') &&
                    Auth.hasItemPermission('edit', $scope.item);
            };

            function initItemTitle() {
                var layout = $rootScope.dataModel.layout($scope.currentLayout);
                if (!_.isNil(layout.layoutOptions) && !_.isEmpty(layout.layoutOptions.itemTitleFilter)) {
                    var filter = $rootScope.getFilter(layout.layoutOptions.itemTitleFilter);
                    if (_.isNil(filter)) {
                        $log.warn('OnlineCatalog: Filter with name ' + layout.layoutOptions.itemTitleFilter +
                            ' was not found, falling back to using the primary key as a title');
                        $scope.itemTitle = $scope.item.primaryKey__;
                    } else {
                        $scope.itemTitle = filter($scope.item, $rootScope.user, $rootScope.organization);
                    }
                } else {
                    $scope.itemTitle = $scope.item.primaryKey__;
                }
            }

            function init() {
                ReactBridge.mountComponent("ItemDetails", "#item-details-component", {
                    primaryKey: $routeParams.primaryKey,
                    isInEditorMode: $scope.isInEditorMode,
                    prepareItemReview: function (item) {
                        $scope.item = item;
                        $scope.currentItemIndex = $scope.getCurrentItemIndex();
                        $scope.prepareItemReview();
                    },
                });

                ReactBridge.mountComponent("ItemDetailsToolbar", "#item-details-toolbar", {
                    primaryKey: $routeParams.primaryKey,
                    startMappingEditor: function (selection, exportType) {
                            ExportMappingResource.query(
                                function(response) {
                                    $modal.open({
                                        templateUrl: 'tpl/export-mapping-editor.tpl.html',
                                        controller: 'ExportMappingController',
                                        resolve: {
                                            data: function() {
                                                return {
                                                    profiles: response.data || [],
                                                    selectedItems: selection,
                                                    parentscope: $scope,
                                                    exportType: exportType,
                                                    selectedFullItems : $scope.getSelectedFullItems()
                                                };
                                            }
                                        },
                                        backdrop: true,
                                        size: 'lg',
                                        windowClass: ''
                                    });
                                });
                        },
                    startTemplateMappingSelector: function() {
                        $modal.open({
                            templateUrl: 'tpl/template-mapping/modal-template-mapping-selector.tpl.html',
                            controller: 'TemplateMappingSelectorController',
                            resolve: {
                                data: function () {
                                    return {
                                        parentScope: $scope
                                    };
                                }
                            },
                            size: 'lg',
                            backdrop: true
                        });
                    }
                });
            }
    });

angular.module('llax')
    .service('LocalCacheService', function($log, $q, $rootScope) {

        var _this = this;
        _this.initialized = false;

        //*** Please keep the DB config in sync with useIndexedDB hook in the react app.
        var SCHEMA_VERSION = 4;
        var DATABASE_NAME = 'bManagedCacheStore';
        var db = null;
        var DBOpenRequest = window.indexedDB.open(DATABASE_NAME, SCHEMA_VERSION);

        this.STORES = {
            optionLists: {
                objectStoreName: 'optionLists',
                keys: ['name', 'dataModelHash'],
                indexes: {
                    optionListName: 'name'
                }
            },
            translations: {
                objectStoreName: 'translations',
                keys: ['languageKey'],
                indexes: {
                    languageKey: 'languageKey'
                }
            },
            additionalCategories: {
                objectStoreName: 'additionalCategories',
                keys: ['additionalModule', 'additionalCategoryNameOrCode', 'dataModelHash'],
                indexes: {
                    additionalCategoryNameOrCode: 'additionalCategoryNameOrCode'
                }
            }
        };

        DBOpenRequest.onsuccess = function(event) {
            $log.debug('LocalCacheService: Created IndexedDB database.');

            db = DBOpenRequest.result;
            _this.initialized = true;

            // Shared database object with React app
            window.__IDB = db;
        };

        DBOpenRequest.onupgradeneeded = function(event) {
            // We can only create object stores in this step, so change the SCHEMA_VERSION every time you change
            // this function (adding new stores or indexes), otherwise `onupgradeneeded` won't be invoked.
            // Make sure to drop any object stores that were introduced in previous versions.
            db = event.target.result;

            db.onerror = function(event) {
                $log.error('LocalCacheService: Error loading IndexedDB database.', event);
            };

            var optionListsNeedMigration = db.objectStoreNames.contains(_this.STORES.optionLists.objectStoreName);
            var translationsNeedMigration = db.objectStoreNames.contains(_this.STORES.translations.objectStoreName);
            var additionalCategoriesNeedMigration =
                db.objectStoreNames.contains(_this.STORES.additionalCategories.objectStoreName);

            if (optionListsNeedMigration) {
                db.deleteObjectStore(_this.STORES.optionLists.objectStoreName);
            }
            if (translationsNeedMigration) {
                db.deleteObjectStore(_this.STORES.translations.objectStoreName);
            }
            if (additionalCategoriesNeedMigration) {
                db.deleteObjectStore(_this.STORES.additionalCategories.objectStoreName);
            }

            optionListsObjectStore = db.createObjectStore(_this.STORES.optionLists.objectStoreName, {
                keyPath: _this.STORES.optionLists.keys
            });
            optionListsObjectStore.createIndex(_this.STORES.optionLists.indexes.optionListName,
                                               _this.STORES.optionLists.indexes.optionListName,
                                               { unique: false });
            translationsObjectStore = db.createObjectStore(_this.STORES.translations.objectStoreName, {
                keyPath: _this.STORES.translations.keys
            });
            translationsObjectStore.createIndex(_this.STORES.translations.indexes.languageKey,
                                               _this.STORES.translations.indexes.languageKey,
                                               { unique: false });

            var additionalCategoriesObjectStore = db.createObjectStore(_this.STORES.additionalCategories.objectStoreName, {
                keyPath: _this.STORES.additionalCategories.keys
            });
            additionalCategoriesObjectStore.createIndex(_this.STORES.additionalCategories.indexes.additionalCategoryNameOrCode,
                                                        _this.STORES.additionalCategories.indexes.additionalCategoryNameOrCode,
                                                        { unique: false });
        };

        this.isInitialized = function() {
            return _this.initialized;
        };

        this.insertEntryAsync = function(storeName, key, entry) {

            var deferred = $q.defer();

            if (!_this.initialized) {
                $log.error('LocalCacheService: Premature call to insertEntryAsync()', storeName, key, entry);
                deferred.reject();
                return deferred.promise;
            }

            var transaction, objectStore, request;
            try {
                transaction = db.transaction([storeName], 'readwrite');
                objectStore = transaction.objectStore(storeName);
                entry.translatedLanguage = $rootScope.language;
                request = objectStore.add(entry);
            } catch (exception) {
                $log.error('LocalCacheService: insertEntryAsync():', key, exception);
                deferred.resolve(null);
                return deferred.promise;
            }

            request.onsuccess = function(event) {
                $log.debug('LocalCacheService: Successfully inserted entry with key', key);
                deferred.resolve();
            };

            request.onerror = function(event) {
                $log.error('LocalCacheService: Error while trying to insert an entry', event);
                deferred.reject();
            };

            return deferred.promise;
        };

        this.deleteEntryAsync = function(storeName, key) {

            var deferred = $q.defer();

            if (!_this.initialized) {
                $log.error('LocalCacheService: Premature call to deleteEntryAsync()', storeName, key);
                deferred.reject();
                return deferred.promise;
            }

            var transaction, objectStore, request;
            try {
                transaction = db.transaction([storeName], 'readwrite');
                objectStore = transaction.objectStore(storeName);

                request = objectStore.delete(key);
            } catch (exception) {
                $log.error('LocalCacheService: deleteEntryAsync():', key, exception);
                deferred.resolve(null);
                return deferred.promise;
            }

            request.onsuccess = function(event) {
                $log.debug('LocalCacheService: Successfully deleted entry with key', key);
                deferred.resolve(request.result);
            };

            request.onerror = function(event) {
                $log.error('LocalCacheService: Error while trying to delete an entry with key', key, event);
                deferred.reject();
            };

            return deferred.promise;
        };

        this.deleteEntriesByIndexAsync = function(storeName, indexName, key) {

            var deferred = $q.defer();

            if (!_this.initialized) {
                $log.error('LocalCacheService: Premature call to deleteEntriesByIndexAsync()', storeName, indexName, key);
                deferred.reject();
                return deferred.promise;
            }

            var index, transaction, objectStore, cursorRequest;
            try {
                transaction = db.transaction([storeName], 'readwrite');
                objectStore = transaction.objectStore(storeName);
                index = objectStore.index(indexName);

                cursorRequest = index.openKeyCursor(IDBKeyRange.only(key));
            } catch (exception) {
                $log.error('LocalCacheService: deleteEntriesByIndexAsync():', indexName, key, exception);
                deferred.resolve(null);
                return deferred.promise;
            }

            cursorRequest.onsuccess = function(event) {
                var cursor = cursorRequest.result;
                if (!_.isNil(cursor)) {
                    objectStore.delete(cursor.primaryKey);
                    cursor.continue();
                } else {
                    deferred.resolve(null);
                }
            };

            cursorRequest.onerror = function(event) {
                $log.error('LocalCacheService: deleteEntriesByIndexAsync()', key, event);
                deferred.resolve(null);
            };

            return deferred.promise;
        };

        this.getEntryAsync = function(storeName, key) {

            var deferred = $q.defer();

            if (!_this.initialized) {
                $log.error('LocalCacheService: Premature call to getEntryAsync()', storeName, key);

                // Resolve as a cache miss
                deferred.resolve(null);
                return deferred.promise;
            }

            var transaction, objectStore, request;
            try {
                transaction = db.transaction([storeName]);
                objectStore = transaction.objectStore(storeName);

                request = objectStore.get(key);
            } catch (exception) {
                $log.error('LocalCacheService: getEntryAsync():', key, exception);
                deferred.resolve(null);
                return deferred.promise;
            }

            request.onsuccess = function(event) {
                var result = request.result;
                if (_.isNil(result)) {
                    $log.debug('LocalCacheService: Cache miss for key', key);
                    deferred.resolve(null);
                } else {
                    $log.debug('LocalCacheService: Cache hit for key', key);
                    deferred.resolve(result);
                }
            };

            request.onerror = function(event) {
                // Error event is fired even for non-existing keys
                $log.debug('LocalCacheService: Cache miss for key', key, event);

                // Resolve as a cache miss instead of throwing an error, makes it
                // easier for callers to handle cache misses or errors.
                deferred.resolve(null);
            };

            return deferred.promise;
        };

        this.clearAllByStoreNameAsync = function(storeName) {
            var deferred = $q.defer();
            var transaction, objectStore, cursorRequest;

            if (!_this.initialized) {
                $log.error('LocalCacheService: Premature call to clearAllByStoreNameAsync()', storeName);
                deferred.reject();
                return deferred.promise;
            }

            try {
                transaction = db.transaction([storeName], 'readwrite');
                objectStore = transaction.objectStore(storeName);
                // Open a cursor to iterate through all entries in the object store
                cursorRequest = objectStore.openCursor();
            } catch (exception) {
                $log.error('LocalCacheService: clearAllByStoreNameAsync() - Error during the transaction:', exception);
                deferred.resolve(null);
                return deferred.promise;
            }
            cursorRequest.onsuccess = function(event) {
                var cursor = event.target.result;
                if (cursor) {
                    // Delete each entry in the object store
                    objectStore.delete(cursor.primaryKey);
                    cursor.continue();
                } else {
                    // All entries have been deleted, resolve the promise
                    $log.debug('LocalCacheService: Successfully cleared all entries in store:', storeName);
                    deferred.resolve();
                }
            };
            cursorRequest.onerror = function(event) {
                $log.error('LocalCacheService: clearAllByStoreNameAsync() - Error during cursor request:', event);
                deferred.resolve(null);
            };
            return deferred.promise;
        };

        this.waitForDBInitialization = function() {
            var deferred = $q.defer();
            if (_this.initialized) {
                deferred.resolve();
            } else {
                var interval = setInterval(function() {
                    if (_this.initialized) {
                        clearInterval(interval);
                        deferred.resolve();
                    }
                }, 500); // Check every 500 milliseconds
            }
            return deferred.promise;
        };
    });
